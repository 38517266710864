import React, {  useCallback, useEffect, useState } from 'react'
import Consultation from './Consultaion/Consultation';
import Lab from './Lab/Lab';
import AppointModal from './AppointModal';
import { getConsultatoinAppointments } from '../../utils/bookConsultation/ConsultationUtils';
import { getLabAppointments } from '../../utils/bookLab/labUtils';
import FollowUpModal from '../FollowUpAppointmentComponents/FollowUpModal';
import FollowUpLabModal from '../FollowUpAppointmentComponents/FollowUpLabModal';

const AppointMainSection = () => {
  const [selected, setSelected] = useState(1);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [consultationAppointments, setConsultaionAppointments] = useState([]);
  const [followUp,setFollowUp] = useState(null);
  const [labFollowUp,setLabFollowUp] = useState(null);
  const [labAppointments, setLabAppointments] = useState([]);
  const setLabAppointmentsFn = useCallback(() => {
    getLabAppointments()
      .then(res => { setLabAppointments(res.data.data) })
      .catch(err => console.log(err));
  }, [])
  const setconsultationAppointmentsFn = useCallback(() => {
    getConsultatoinAppointments()
      .then(res => { setConsultaionAppointments(res.data.data) })
      .catch(err => console.log(err));
  },[])
  useEffect(() => {
    setconsultationAppointmentsFn(); setLabAppointmentsFn()
  },[setconsultationAppointmentsFn, setLabAppointmentsFn]);
  return (
    <>
      {open && <AppointModal setOpen={setOpen} editData={editData} setEditData={setEditData} selectedMode={selected} setAppointmentsFn={selected===1 ? setconsultationAppointmentsFn : setLabAppointmentsFn}/> }
      {followUp && <FollowUpModal followUp={followUp} setFollowUp={setFollowUp}/> }
      {labFollowUp && <FollowUpLabModal followUp={labFollowUp} setFollowUp={setLabFollowUp}/> }
    <div className="flex flex-col w-full h-full">
      <div className="flex w-full justify-between items-center gap-3">
        <div className="flex gap-3">
          <button
            onClick={() => setSelected(1)}
            className={`flex px-3 border border-primary py-1 rounded-md ${
              selected === 1 ? "bg-primary text-white" : "bg-white text-primary"
            }`}
          >
            Consultation
          </button>
          <button
            onClick={() => setSelected(2)}
            className={`flex px-3 border border-primary py-1 rounded-md ${
              selected === 2 ? "bg-primary text-white" : "bg-white text-primary"
            }`}
          >
            Lab
          </button>
        </div>
        <div className="flex">
          <button
            onClick={()=>setOpen(true)}
            className={`flex px-3 border border-primary py-1 rounded-md bg-primary text-white`}
          >
            Appointment
          </button>
        </div>
      </div>
      {selected === 1 && <Consultation appointments={consultationAppointments}  setOpen={setOpen} setEditData={setEditData} setAppointmentsFn={setconsultationAppointmentsFn} setFollowUp={setFollowUp} />}
      {selected === 2 && <Lab appointments={labAppointments} setOpen={setOpen} setEditData={setEditData}  setLabsAppointmentsFn={setLabAppointmentsFn} setFollowUp={setLabFollowUp} />}
      </div>
    </>
  );
}

export default AppointMainSection
export const validateConsultaion = (initialState, doctor, time, date, setError) => {
    const namePattern = /^[A-Za-z\s.]+$/;
    // const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const genderPattern = /^(?!Select Gender$).+/;
    const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;
    if (!namePattern.test(initialState.name)) {
        setError({ global: "Invalid Name" });
        return false;
    }

    if (!phoneNumberPattern.test(initialState.contact)) {
        setError({ global: "Invalid Phone Number" });
        return false;
    }

    if (!genderPattern.test(initialState.gender)) {
        setError({ global: "Please Select Gender" });
        return false;
    }
    
    // if (initialState.email.length && (!emailPattern.test(initialState.email))) {
    //     setError({ global: "Invalid Email" });
    //     return false;
    // }

    if (!namePattern.test(initialState.record)) {
        setError({ global: "Invalid Record" });
        return false;
    }

    if (initialState.status === "Select Status") {
        setError({ global: "Please Select Payment Status" });
        return false;
    }

    if (initialState.platform === "Select Platform") {
        setError({ global: "Please Select Platform" });
        return false;
    }

    if (!doctor) {
        setError({ global: "Please Select Doctor" });
        return false;
    }

    if (!date) {
        setError({ global: "Please Select Date" });
        return false;
    }

    if (!time) {
        setError({ global: "Please Select Time" });
        return false;
    }

    return true;
}


export const validateFollowupConsultaion = (initialState, doctor, time, date, setError) => {
    const namePattern = /^[A-Za-z\s.]+$/;
    // const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;
    if (!namePattern.test(initialState.name)) {
        setError({ global: "Invalid Name" });
        return false;
    }

    if (!phoneNumberPattern.test(initialState.contact)) {
        setError({ global: "Invalid Phone Number" });
        return false;
    }
    
    // if (initialState.email.length && (!emailPattern.test(initialState.email))) {
    //     setError({ global: "Invalid Email" });
    //     return false;
    // }

    if (initialState.status === "Select Status") {
        setError({ global: "Please Select Payment Status" });
        return false;
    }

    if (!doctor) {
        setError({ global: "Please Select Doctor" });
        return false;
    }

    if (!date) {
        setError({ global: "Please Select Date" });
        return false;
    }

    if (!time) {
        setError({ global: "Please Select Time" });
        return false;
    }

    return true;
}

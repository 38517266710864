import React from 'react'
import { AppointMainSection } from '../../components/AppointmentsComponents'

const Appointments = () => {
  return (
    <div className="w-full h-full p-10 flex flex-col gap-4 relative">
      <AppointMainSection />
    </div>
  );
}

export default Appointments
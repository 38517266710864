import React from "react";
import { FollowUpAppointmentsMainSection } from "../../components/FollowUpAppointmentComponents";

const FollowsAppointments = () => {
  return (
    <div className="w-full h-full p-10 flex flex-col gap-4 relative">
     <FollowUpAppointmentsMainSection/>
    </div>
  );
};

export default FollowsAppointments;

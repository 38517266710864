export const validataLabForm = (initialState, lab, setError) => {
    const namePattern = /^[A-Za-z\s.]+$/;
    // const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

    if (!namePattern.test(initialState.name)) {
        setError({ global: "Invalid Name" });
        return false;
    }

    if (!phoneNumberPattern.test(initialState.contact)) {
        setError({ global: "Invalid Phone Number" });
        return false;
    }

    // if (initialState.email.length && (!emailPattern.test(initialState.email))) {
    //     setError({ global: "Invalid Email" });
    //     return false;
    // }

    if (!namePattern.test(initialState.test)) {
        setError({ global: "Invalid Test" });
        return false;
    }

    if (initialState.status === "Select Status") {
        setError({ global: "Please Select Payment Status" });
        return false;
    }

    if (initialState.platform === "Select Platform") {
        setError({ global: "Please Select Platform" });
        return false;
    }

    if (!lab) {
        setError({ global: "Please Lab" });
        return false;
    }

    return true;
}

export const validataLabFollowupForm = (initialState, lab, setError) => {
    const namePattern = /^[A-Za-z\s.]+$/;
    // const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

    if (!namePattern.test(initialState.name)) {
        setError({ global: "Invalid Name" });
        return false;
    }

    if (!phoneNumberPattern.test(initialState.contact)) {
        setError({ global: "Invalid Phone Number" });
        return false;
    }

    // if (initialState.email.length && (!emailPattern.test(initialState.email))) {
    //     setError({ global: "Invalid Email" });
    //     return false;
    // }

    if (!namePattern.test(initialState.test)) {
        setError({ global: "Invalid Test" });
        return false;
    }

    if (initialState.status === "Select Status") {
        setError({ global: "Please Select Payment Status" });
        return false;
    }

    if (!lab) {
        setError({ global: "Please Select Lab" });
        return false;
    }

    return true;
}

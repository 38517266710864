import React, { useEffect, useMemo, useState } from "react";
import Table from "../../common/Table/Table";
import SearchInput from "../../common/SearchInput/SearchInput";
import { NoDataFound } from "../../common";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { deleteLabApponintment } from "../../../utils/bookLab/labUtils";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { FaFileDownload } from "react-icons/fa";

const Lab = ({
  appointments,
  setOpen,
  setEditData,
  setLabsAppointmentsFn,
  setFollowUp,
}) => {
  const exportToXLSX = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Lab Appointments Data");
    data = filteredData.map((item) => {
      return {
        name: item?.name,
        phone_number: item?.phone_number,
        patient_id: item?.patient_id,
        visit_id: item?.visit_id,
        test: item?.lab_test?.test_name,
        payment_status: item?.payment_status,
      };
    });
    const headings = [
      { header: "Name", key: "name", width: 20 },
      { header: "Phone", key: "phone_number", width: 20 },
      { header: "Patient ID", key: "patient_id", width: 15 },
      { header: "Visit ID", key: "visit_id", width: 15 },
      { header: "Test", key: "test", width: 20 },
      { header: "Payment Status", key: "payment_status", width: 20 },
    ];

    worksheet.columns = headings;
    data.forEach((item) => {
      worksheet.addRow(item);
    });
    worksheet.getRow(1).font = { bold: true };
    worksheet.columns.forEach((column) => {
      column.alignment = { vertical: "middle", horizontal: "center" };
    });
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Appointments.xlsx");
  };
  const headings = useMemo(
    () => [
      "Name",
      "P-ID",
      "V-ID",
      "Contact",
      "Test",
      "Status",
      "Platform",
      "Edit",
      "Delete",
      "Follow",
    ],
    []
  );
  const delLabAppointment = (appointmentId) => {
    deleteLabApponintment(appointmentId)
      .then((res) => setLabsAppointmentsFn())
      .catch((err) => console.log(err));
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((data) => {
      const nameMatch = data.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const pIdMatch = data.patient_id
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const vIdMatch = data.visit_id
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const numberMatch = data.phone_number
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const testMatch = data.lab_test?.test_name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || numberMatch || pIdMatch || testMatch || vIdMatch;
    });
    setFilteredData(filteredData);
  }, [appointments, searchQuery]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLabsAppointmentsFn();
    }, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [setLabsAppointmentsFn]);
  return (
    <>
      {" "}
      <div className="flex w-full justify-end flex-col sm:flex-row gap-3 mt-5">
        <div
          className={`${
            filteredData?.length > 0 ? "flex" : "hidden"
          } gap-2 items-end `}
        >
          <button
            className="text-white bg-primary text-base rounded-md px-3 py-1 h-fit flex gap-1 items-center"
            onClick={() =>
              exportToXLSX(filteredData)
            }
          >
            Download <FaFileDownload />
          </button>
        </div>
      </div>
      <div className="h-full  w-full mt-3">
        <div className="flex w-full justify-center items-center mb-5">
          <div className="flex w-full sm:w-1/2">
            <SearchInput
              placeholder={"Search"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
        {filteredData?.length > 0 ? (
          <Table headings={headings}>
            {filteredData?.map((data, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{data?.name}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {data?.patient_id}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 capitalize">
                    {data?.visit_id}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 capitalize">
                    {data?.phone_number}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 capitalize">
                    {data?.lab_test?.test_name}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 capitalize">
                    {data?.payment_status}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 capitalize">
                    {data?.social_media}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div
                    className="text-sm text-gray-900 capitalize cursor-pointer"
                    onClick={() => {
                      setEditData(data);
                      setOpen(true);
                    }}
                  >
                    <CiEdit />
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div
                    className="text-sm text-gray-900 capitalize cursor-pointer"
                    onClick={() => {
                      delLabAppointment(data?._id);
                    }}
                  >
                    <MdOutlineDeleteOutline color="red" />
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    className="text-sm capitalize px-3 py-1 bg-blue-500 rounded-md text-white"
                    onClick={() => {
                      setFollowUp(data);
                    }}
                  >
                    Add
                  </button>
                </td>
              </tr>
            ))}
          </Table>
        ) : (
          <NoDataFound data={"appointments"} />
        )}
      </div>
    </>
  );
};

export default Lab;

import React from 'react'
import { OnlineAppointMainSection } from '../../components/OnlineAppointemntsComponents';

const OnlineAppointments = () => {
  return (
    <div className="w-full h-full p-10 flex flex-col gap-4 relative">
      <OnlineAppointMainSection/>
    </div>
  );
}

export default OnlineAppointments
import React, { useCallback, useEffect, useState } from 'react';
import { getConsultationFollowupAppointments } from '../../utils/bookConsultation/ConsultationUtils';
import {  getLabFollowupAppointments } from '../../utils/bookLab/labUtils';
import FollowUpLabAppointmentsTable from './FollowUpLabAppointmentsTable';
import FollowUpConsultationAppointmentTable from './FollowUpAppointmentsConsultationTable';

const FollowUpAppointmentMainSection = () => {
  const [selected, setSelected] = useState(1);
  const [consultationAppointments, setConsultaionFollowupAppointments] = useState([]);
  const [followupLabAppointments, setLabFollowupAppointments] = useState([]);
  const setLabFollowupAppointmentsFn = useCallback(() => {
    getLabFollowupAppointments()
      .then(res => { setLabFollowupAppointments(res.data.data) })
      .catch(err => console.log(err));
  }, [])
  const setconsultationFollowupAppointmentsFn = useCallback(() => {
    getConsultationFollowupAppointments()
      .then(res => { setConsultaionFollowupAppointments(res.data.data) })
      .catch(err => console.log(err));
  },[])
  useEffect(() => {
    setconsultationFollowupAppointmentsFn(); setLabFollowupAppointmentsFn()
  },[setconsultationFollowupAppointmentsFn, setLabFollowupAppointmentsFn]);
  return (
    <>
    <div className="flex flex-col w-full h-full">
      <div className="flex w-full justify-between items-center gap-3">
        <div className="flex gap-3">
          <button
            onClick={() => setSelected(1)}
            className={`flex px-3 border border-primary py-1 rounded-md ${
              selected === 1 ? "bg-primary text-white" : "bg-white text-primary"
            }`}
          >
            Consultation
          </button>
          <button
            onClick={() => setSelected(2)}
            className={`flex px-3 border border-primary py-1 rounded-md ${
              selected === 2 ? "bg-primary text-white" : "bg-white text-primary"
            }`}
          >
            Lab
          </button>
        </div>
        <div className="flex">
          
        </div>
      </div>
      {selected === 1 && <FollowUpConsultationAppointmentTable appointments={consultationAppointments} setAppointmentsFn={setConsultaionFollowupAppointments}/>}
      {selected === 2 && <FollowUpLabAppointmentsTable appointments={followupLabAppointments} setAppointmentsFn={setLabFollowupAppointmentsFn} />}
      </div>
    </>
  );
}

export default FollowUpAppointmentMainSection
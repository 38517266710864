import React, { useCallback, useEffect, useState } from "react";
import { IoArrowBackSharp } from "react-icons/io5";
import { getSchedule } from "../../../utils/scheduleUtils/scheduleUtils";

// Define common styles
const buttonStyle =
  "flex border text-xs cursor-pointer border-primary rounded-md w-[80px] h-[25px] justify-center items-center";
const bookedStyle = "bg-red-600 text-white";
const selectedStyle = "bg-primary text-white";
const defaultStyle = "text-primary bg-white";

const DoctorData = ({ data, setData, doctor, setErr }) => {
  const { _id, name, doctor_id, doctor_image, department_id } = doctor;
  const { date = null, time = null } = data || {};
  const [schedules, setSchedules] = useState([]);
  const setSchedulesFn = useCallback(() => {
    getSchedule(doctor?._id)
      .then((res) => setSchedules(res.data.data))
      .catch((err) => console.log(err));
  }, [doctor?._id]);

  useEffect(() => {
    setSchedulesFn();
  }, [setSchedulesFn]);

  const renderScheduleButton = (slot, slotType) => (
    <button
      key={slot?._id}
      disabled={slot.isBooked}
      onClick={() => handleButtonClick(slotType, slot.time)}
      className={`${buttonStyle} ${
        slot.isBooked
          ? bookedStyle
          : slot.time === time
          ? selectedStyle
          : defaultStyle
      }`}
    >
      {slot?.time}
    </button>
  );

  const handleButtonClick = (slotType, slotTime) => {
    setErr({ global: "" });
    setData({
      doctor,
      slot: slotType,
      date,
      time: slotTime,
    });
  };

  return (
    <div
      className={`flex w-full h-fit rounded-md border p-2 gap-3 mb-2 ${
        _id === (data?.doctor?._id ?? "") ? "bg-slate-100" : "bg-white"
      }`}
    >
      <img
        src={doctor_image}
        className="w-[130px] h-full rounded-md object-cover"
        alt=""
      />
      <div className="flex flex-col gap-2 w-full">
        <span className="text-base">{name}</span>
        <span className="text-sm">
          {doctor_id} | {department_id?.department}
        </span>
        <span className="text-black text-sm font-semibold w-full flex gap-10">
          <span className={"flex"}>
            {date ? `Selected Date - ${date.date}` : "Available Dates:"}
          </span>
          <button
            onClick={() => {
              setData({ date: null });
            }}
            className={`border border-primary px-2 rounded-md flex gap-1 items-center ${
              date ? "flex" : "hidden"
            }`}
          >
            <IoArrowBackSharp />
            Back
          </button>
        </span>

        {!date && (
          <div className="flex flex-wrap gap-2">
            {schedules?.[0]?.schedules?.length > 0 ? (
              schedules[0].schedules.map((data) => (
                <button
                  key={data?._id}
                  onClick={() => {
                    setErr({ global: "" });
                    setData({ date: data, doctor });
                  }}
                  className={buttonStyle}
                >
                  {data?.date}
                </button>
              ))
            ) : (
              <span className="w-full justify-center flex text-red-600 text-base font-semibold">
                No Available Dates!
              </span>
            )}
          </div>
        )}

        {date && data?.date?.morning.length > 0 && (
          <>
            <span className="text-black text-sm font-semibold w-full flex">
              Morning -
            </span>
            <div className="flex flex-wrap gap-2">
              {data?.date?.morning?.map((slot) =>
                renderScheduleButton(slot, "Morning")
              )}
            </div>
          </>
        )}

        {date && data?.date?.afternoon.length > 0 && (
          <>
            <span className="text-black text-sm font-semibold w-full flex">
              Afternoon -
            </span>
            <div className="flex flex-wrap gap-2">
              {data?.date?.afternoon?.map((slot) =>
                renderScheduleButton(slot, "Afternoon")
              )}
            </div>
          </>
        )}

        {date && data?.date?.evening.length > 0 && (
          <>
            <span className="text-black text-sm font-semibold w-full flex">
              Evening -
            </span>
            <div className="flex flex-wrap gap-2">
              {data?.date?.evening?.map((slot) =>
                renderScheduleButton(slot, "Evening")
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DoctorData;

import { axiosInstance } from "../../api/config";

export const getConsultatoinAppointments = async () => {
    try {
        const response = await axiosInstance.get(`/reception/get_consultant`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getOnlineConsultatoinAppointments = async () => {
    try {
        const response = await axiosInstance.get(`/appointment/get_appointment`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addConsultationAppointment = async (initialState, doctor,time,slot,date, appointmentId=null) => {
    try {
        const requestData = {
            name: initialState.name,
            phone_number: initialState.contact,
            email: initialState.email || '',
            disease: initialState.record,
            doctor_id: doctor?._id,
            date: date.date,
            gender: initialState.gender,
            dateObject: date,
            slot,
            time,
            payment_status: initialState.status,
            social_media: initialState.platform
        };
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const url = appointmentId
            ? `/reception/update_consultant/${appointmentId}`
            : `/reception/create_consultant`;
        const response = await axiosInstance[appointmentId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const verfiyConsultationBookingOtp = async (initialState, otp, setErr) => {
    try {
        const requestData = {
            patient_id: initialState.patient_id,
            otpVerify: otp
        }
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const response = await axiosInstance.post(`/consultant/verify`, requestData, requestConfig);
        return response;
    } catch (error) {
        setErr({ global: "Otp Not Matching" })
        throw error;
    }
}

export const verfiyFollowupConsultationBookingOtp = async (initialState, otp, setErr) => {
    try {
        const requestData = {
            patient_id: initialState.patient_id,
            otpVerify: otp
        }
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const response = await axiosInstance.post(`/consultant_followup/verify`, requestData, requestConfig);
        return response;
    } catch (error) {
        setErr({ global: "Otp Not Matching" })
        throw error;
    }
}


export const deleteApponintment = async(appointmentId) => {
    try {
        const response = await axiosInstance.delete(`/reception/delete_consultant/${appointmentId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getConsultationFollowupAppointments = async () => {
    try {
        const response = await axiosInstance.get(`/receptionconsultantfollowup`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addConsultationFollowupAppointments = async (initialState, doctor, time, slot, date, patient_id) => {
    try {
        const requestData = {
            name: initialState.name,
            phone_number: initialState.contact,
            email: initialState.email || '',
            doctor_id: doctor?._id,
            date: date.date,
            gender: initialState.gender,
            dateObject: date,
            slot,
            time,
            payment_status: initialState.status,
            patient_id
        };
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const url = `/reception/create_consultantfollowup`;
        const response = await axiosInstance['post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteConsultationFollowupApponintment = async (appointmentId) => {
    try {
        const response = await axiosInstance.delete(`/reception/delete_consultantfollowup/${appointmentId}`);
        return response;
    } catch (error) {
        throw error;
    }
}
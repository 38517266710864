import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks";
import { SelectInput, TextInput } from "../common/FormComponents";
import { NoDataFound, SearchInput } from "../common";
import {  addLabFollowupAppointments, getTests } from "../../utils/bookLab/labUtils";
import {validataLabFollowupForm} from "../../utils/bookLab/validateLabForm";
import { GrFormClose } from "react-icons/gr";

const FollowUpLabModal = ({
  followUp=null, setFollowUp
}) => {
    const initialState = {
      name: followUp?.name || "",
      contact: followUp?.phone_number || "",
      email: followUp?.email || "",
      status: "Select Status",
      doctor_name: followUp?.doctor_name || "",
    };
    const [tests, setTests] = useState([]);
    useEffect(() => {
      getTests()
        .then((res) => {
          setTests(res.data.data);
        })
        .catch((err) => console.log(err));
    }, []);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
      const filteredData = tests?.filter((test) => {
        const nameMatch = test.test_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const tIdMatch = test.test_id
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const departmentMatch = test.department
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const categoryMatch = test.category
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        return nameMatch || tIdMatch || departmentMatch || categoryMatch;
      });
      setFilteredData(filteredData);
    }, [tests, searchQuery]);
    const [testData, setTestData] = useState({
      test: followUp?.lab_test || null,
    });
    const [formData, handleInput, clearForm] = useForm(initialState);
    const [err, setError] = useState({ global: "" });
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validataLabFollowupForm(formData, testData?.test, setError)) {
        setLoading(true);
        addLabFollowupAppointments(
          formData,
          testData?.test,
          followUp?.patient_id
        )
          .then((res) => {
            setFollowUp(null);
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      }
    };
  return (
    <div className="absolute flex w-full h-full  top-0 left-0 bottom-0 justify-center items-center p-4  ">
      <div
        className={`flex bg-white w-full max-w-screen-lg overflow-y-auto h-full border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col`}
      >
        <div className="flex w-full h-fit items-center justify-between">
          <div className="flex justify-center items-center  gap-3 font-semibold">
            Add Follow Up
          </div>
          <span
            className="cursor-pointer"
            onClick={() => {
              setFollowUp(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        {(
          <>
            <div className="flex w-full h-fit justify-between gap-5">
              <TextInput
                name="Name"
                formName="name"
                data={formData}
                handleInput={handleInput}
                setError={setError}
              />
              <TextInput
                name="Phone"
                formName="contact"
                data={formData}
                handleInput={handleInput}
                setError={setError}
              />
              <TextInput
                name="Email"
                notShow={true}
                formName="email"
                data={formData}
                handleInput={handleInput}
                setError={setError}
              />
            </div>
            <div className="flex w-full h-fit justify-between gap-5">
              <TextInput
                name="Doctor Name"
                formName="doctor_name"
                data={formData}
                handleInput={handleInput}
                setError={setError}
              />
              <SelectInput
                name="Status"
                formName="status"
                items={["Select Status", "Pending", "Paid"]}
                data={formData}
                handleInput={handleInput}
                setError={setError}
              />
            </div>
            <div className="flex w-full">
              <SearchInput
                placeholder="Search Test"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
            {filteredData?.length > 0 ? (
              <div className="w-full overflow-y-auto">
                {filteredData.map((test, index) => (
                  <div
                    key={index}
                    onClick={() => setTestData({ test })}
                    className={`flex cursor-pointer w-full h-fit  p-2 gap-3 items-center mb-2 border rounded-md ${
                      test?._id === testData?.test?._id
                        ? "bg-slate-100"
                        : "bg-white"
                    }`}
                  >
                    <div className="flex w-[50px] h-[50px] rounded-full overflow-hidden">
                      <img
                        className="h-full w-full object-cover"
                        src={test?.test_pic}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className="flex items-end gap-2">
                        <span className="text-base">{test?.test_name}</span> |{" "}
                        <span className="text-sm">{test?.test_id}</span>
                      </span>
                      <span className="text-xs">
                        department: {test?.department}
                      </span>
                      <span className="text-xs">
                        category : {test?.category}
                      </span>
                      <span className="text-xs">
                        price : ₹{test?.total_price}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <NoDataFound data="labs" />
            )}
            {err.global && (
              <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
                {err.global}
              </div>
            )}
            <div className="flex w-full h-fit justify-between gap-5">
              <div className=" flex flex-col w-full gap-0.5 ">
                <button
                  className="w-full border border-primary py-1 rounded-md bg-white text-primary text-shadow-black flex justify-center items-center"
                  onClick={() => {
                    clearForm();
                    setFollowUp(null);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className=" flex flex-col w-full gap-0.5 ">
                <button
                  className="w-full border bg-primary py-1 rounded-md text-white border-primary text-shadow-black flex justify-center items-center"
                  disabled={loading}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FollowUpLabModal;

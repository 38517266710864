import React from 'react'
import { GrFormClose } from "react-icons/gr";
import ConsultatoinForm from './Form/ConsultatoinForm';
import LabForm from './Form/LabForm';


const AppointModal = ({setOpen, editData = null, setEditData, selectedMode, setAppointmentsFn }) => {
  return (
    <div className="absolute flex w-full h-full  top-0 left-0 bottom-0 justify-center items-center p-4  ">
      <div
        className={`flex bg-white w-full max-w-screen-lg overflow-y-auto h-full border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col`}
      >
        <div className="flex w-full h-fit items-center justify-between">
          <div className="flex justify-center items-center  gap-3">
            <button
              className={`flex px-3 border border-primary py-1 rounded-md ${
                selectedMode === 1
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              Consultation
            </button>
            <button
              className={`flex px-3 border border-primary py-1 rounded-md ${
                selectedMode === 2
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              Lab
            </button>
          </div>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        {selectedMode === 1 && (
          <ConsultatoinForm
            setOpen={setOpen}
            editData={editData}
            setEditData={setEditData}
            setAppointmentsFn={setAppointmentsFn}
          />
        )}
        {selectedMode === 2 && (
          <LabForm
            setOpen={setOpen}
            editData={editData}
            setEditData={setEditData}
            setAppointmentsFn={setAppointmentsFn}
          />
        )}
      </div>
    </div>
  );
}

export default AppointModal
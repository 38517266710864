import React, { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { getDoctors } from "../../utils/doctorUtils/doctorUtils";
import { validateFollowupConsultaion } from "../../utils/bookConsultation/validateConsultationForm";
import { addConsultationFollowupAppointments } from "../../utils/bookConsultation/ConsultationUtils";
import { useForm } from "../../hooks";
import { SelectInput, TextInput } from "../common/FormComponents";
import { NoDataFound, SearchInput } from "../common";
import DoctorData from "../AppointmentsComponents/DoctorData/DoctorData";

const FollowUpModal = ({
  followUp=null, setFollowUp
}) => {
    const initialState = {
         name: followUp?.name || "",
         contact: followUp?.phone_number || "",
         status: "Select Status",
       };
       const [doctors,setDoctors] = useState([]);
       useEffect(()=>{
         getDoctors().then(res => { setDoctors(res.data?.data) }).catch(err => console.log(err));
       }, []);
      const [searchQuery, setSearchQuery] = useState("");
      const [filterdDoctors, setFilteredDoctors] = useState([]);
      useEffect(() => {
      const filteredData = doctors?.filter((doctor) => {
      const nameMatch = doctor.name.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const idMatch = doctor.doctor_id.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || idMatch ;
      });
      setFilteredDoctors(filteredData);
      }, [doctors, searchQuery]);
       const [data, setData] = useState({
         date: followUp?.dateObject || null,
         slot: followUp?.time || null,
         doctor: followUp?.doctor_id || null ,
         time: followUp?.time || null ,
       });
       const [formData, handleInput, clearForm] = useForm(initialState);
       const [err, setError] = useState({ global: "" });
       const [loading, setLoading] = useState(false);
       const handleSubmit = (e) => {
         e.preventDefault();
         const { doctor, time, slot, date } = data;
         if (validateFollowupConsultaion(formData, doctor,time,date, setError)) {
           setLoading(true);
           addConsultationFollowupAppointments(formData, doctor,time,slot,date,followUp?.patient_id)
             .then((res) => {
                setFollowUp(null);
             })
             .catch((err) => console.log(err))
             .finally(()=>setLoading(false));
         }
  };
  return (
    <div className="absolute flex w-full h-full  top-0 left-0 bottom-0 justify-center items-center p-4  ">
      <div
        className={`flex bg-white w-full max-w-screen-lg overflow-y-auto h-full border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col`}
      >
        <div className="flex w-full h-fit items-center justify-between">
          <div className="flex justify-center items-center  gap-3 font-semibold">
            Add Follow Up
          </div>
          <span
            className="cursor-pointer"
            onClick={() => {
              setFollowUp(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
          <>
            <div className="flex w-full h-fit justify-between gap-5">
              <TextInput
                name="Name"
                formName={"name"}
                data={formData}
                handleInput={handleInput}
                setError={setError}
              />
              <TextInput
                name="Phone"
                formName={"contact"}
                data={formData}
                handleInput={handleInput}
                setError={setError}
              />
            </div>
            <div className="flex w-full h-fit justify-between gap-5">
              {/* <TextInput
                name="Email"
                notShow={true}
                formName={"email"}
                data={formData}
                handleInput={handleInput}
                setError={setError}
              /> */}
              <SelectInput
                name="Status"
                formName={"status"}
                items={["Select Status", "Pending", "Paid"]}
                data={formData}
                handleInput={handleInput}
                setError={setError}
              />
            </div>
            <div className="flex w-full">
              <SearchInput
                placeholder={"Search Doctor"}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
            {filterdDoctors?.length > 0 ? (
              <div className="w-full overflow-y-auto">
                {filterdDoctors?.map((doctor, index) => (
                  <DoctorData
                    key={index}
                    setErr={setError}
                    data={doctor?._id === data?.doctor?._id ? data : null}
                    doctor={doctor}
                    setData={setData}
                  />
                ))}
              </div>
            ) : (
              <NoDataFound data={"doctors"} />
            )}
            {err.global && (
              <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
                {err.global}
              </div>
            )}
            <div className="flex w-full h-fit justify-between gap-5">
              <div className=" flex flex-col w-full gap-0.5 ">
                <button
                  className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
                  onClick={() => {
                    clearForm();
                    setFollowUp(null);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className=" flex flex-col w-full gap-0.5 ">
                <button
                  className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
                  disabled={loading}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
      </div>
    </div>
  );
};

export default FollowUpModal;

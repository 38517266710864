import React, { useEffect, useState } from 'react'
import { useForm } from '../../../hooks';
import { SelectInput, TextInput } from '../../common/FormComponents';
import DoctorData from '../DoctorData/DoctorData';
import SearchInput from '../../common/SearchInput/SearchInput';
import { getDoctors } from '../../../utils/doctorUtils/doctorUtils';
import { NoDataFound } from '../../common';
import { validateConsultaion } from '../../../utils/bookConsultation/validateConsultationForm';
import { addConsultationAppointment } from '../../../utils/bookConsultation/ConsultationUtils';

const ConsultatoinForm = ({setOpen,editData=null,setEditData,setAppointmentsFn}) => {
       const initialState = {
         name: editData?.name || "",
         contact: editData?.phone_number || "",
         email: editData?.email || "",
         record: editData?.disease || "",
         gender: editData?.gender || "Select Gender",
         status: editData?.payment_status || "Select Status",
         platform: editData?.social_media || "Select Platform",
       };
       const [doctors,setDoctors] = useState([]);
       useEffect(()=>{
         getDoctors().then(res => { setDoctors(res.data?.data) }).catch(err => console.log(err));
       }, []);
      const [searchQuery, setSearchQuery] = useState("");
      const [filterdDoctors, setFilteredDoctors] = useState([]);
      useEffect(() => {
      const filteredData = doctors?.filter((doctor) => {
      const nameMatch = doctor.name.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const idMatch = doctor.doctor_id.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || idMatch ;
      });
      setFilteredDoctors(filteredData);
      }, [doctors, searchQuery]);
       const [data, setData] = useState({
         date: editData?.dateObject || null,
         slot: editData?.time || null,
         doctor: editData?.doctor_id || null ,
         time: null || editData?.time || null ,
       });
       const [formData, handleInput, clearForm] = useForm(initialState);
       const [err, setError] = useState({ global: "" });
       const [loading, setLoading] = useState(false);
       const handleSubmit = (e) => {
         e.preventDefault();
         const { doctor, time, slot, date } = data;
         if (validateConsultaion(formData, doctor,time,date, setError)) {
           setLoading(true);
           addConsultationAppointment(formData, doctor,time,slot,date,editData?._id)
             .then((res) => {
              setAppointmentsFn();
              setOpen(false);
             })
             .catch((err) => console.log(err))
             .finally(()=>setLoading(false));
         }
  };
  return (
    <>
        <>
          <div className="flex w-full h-fit justify-between gap-5">
            <TextInput
              name="Name"
              formName={"name"}
              data={formData}
              handleInput={handleInput}
              setError={setError}
            />
            <TextInput
              name="Phone"
              formName={"contact"}
              data={formData}
              handleInput={handleInput}
              setError={setError}
            />
            <SelectInput
              data={formData}
              handleInput={handleInput}
              items={["Select Gender", "Male", "Female"]}
              name={"Gender"}
              formName={"gender"}
              setError={setError}
            />
            {/* <TextInput
              name="Email"
              notShow={true}
              formName={"email"}
              data={formData}
              handleInput={handleInput}
              setError={setError}
            /> */}
          </div>
          <div className="flex w-full h-fit justify-between gap-5">
            <TextInput
              name="Record"
              formName={"record"}
              data={formData}
              handleInput={handleInput}
              setError={setError}
            />
            <SelectInput
              name="Status"
              formName={"status"}
              items={["Select Status", "Pending", "Paid"]}
              data={formData}
              handleInput={handleInput}
              setError={setError}
            />
            <SelectInput
              name="Platform"
              formName={"platform"}
              items={[
                "Select Platform",
                "Facebook",
                "Instagram",
                "Website",
                "Snapchat",
                "LinkedIn",
                "X",
                "Google",
                "Walk In"
              ]}
              data={formData}
              handleInput={handleInput}
              setError={setError}
            />
          </div>
          <div className="flex w-full">
            <SearchInput
              placeholder={"Search Doctor"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
          {filterdDoctors?.length > 0 ? (
            <div className="w-full overflow-y-auto">
              {filterdDoctors?.map((doctor, index) => (
                <DoctorData
                  key={index}
                  setErr={setError}
                  data={doctor?._id === data?.doctor?._id ? data : null}
                  doctor={doctor}
                  setData={setData}
                />
              ))}
            </div>
          ) : (
            <NoDataFound data={"doctors"} />
          )}
          {err.global && (
            <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
              {err.global}
            </div>
          )}
          <div className="flex w-full h-fit justify-between gap-5">
            <div className=" flex flex-col w-full gap-0.5 ">
              <button
                className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
                onClick={() => {
                  clearForm();
                  setOpen(false);
                  setEditData(null);
                }}
              >
                Cancel
              </button>
            </div>
            <div className=" flex flex-col w-full gap-0.5 ">
              <button
                className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
                disabled={loading}
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </>
    </>
  );
}

export default ConsultatoinForm